@import "styles/includes.scss";

.Root {
    @include media(m) {
        margin-top: 60px;
    }

    @include media(l) {
        margin-top: 100px;
    }

    &--StartPage {
        @include media(m) {
            margin-top: 0;
        }
    }
}

.Videos {
    position: relative;
    width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
    align-content: stretch;

    flex-wrap: wrap;

    @include media(m) {
        padding-bottom: 200px;
        flex-wrap: nowrap;

        &::after {
            content: '';
            position: absolute;
            left: 140px;
            bottom: -150px;
            width: 500px;
            height: 500px;
            background: url(#{$basepath}img/svg/icon-green-2.svg) no-repeat 20%
                center;
            background-size: contain;
            z-index: $z-behind;
        }
    }
}

.VideoSquareWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 40px;

    @include media(m) {
        width: calc(40% - 60px);
        min-width: calc(40% - 60px);
        margin-right: 60px;

        transform: translate3d(0, 240px, 0);
    }

    @include media(l) {
        width: 40%;
        min-width: 40%;
        margin-right: 0;

        transform: translate3d(-60px, 240px, 0);
    }

    @include media(xl) {
        transform: translate3d(-116px, 240px, 0);
    }
}

.VideoSquareWrapperInner {
    padding-bottom: 100%;
}

.VideoWideWrapper {
    position: relative;
    width: 100%;

    @include media(m) {
        width: 60%;
        min-width: 60%;
    }
}

.VideoWideWrapperInner {
    padding-bottom: 57%;
}
